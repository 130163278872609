@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  @apply font-sans;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button:hover {
  text-decoration: none;
}
